<template>
  <div id="app">
    <img class="banner" src="https://pic5.zhuanstatic.com/zhuanzh/6e8046c9-6dbd-4ff0-b3e1-f46395d7b33f.png" alt="">
    <span class="footer-text"><a href="https://beian.miit.gov.cn/">网站备案/许可证号：{{ text }} </a></span>
  </div>
</template>

<script>
const Dict = {
  'www.zzcn.co': '京ICP备17029143号-4',
  'www.zzzlj.cn':  '京ICP备17029143号-5',
  'www.zz3.cn':  '京ICP备17029143号-6',
  'www.z.zone':  '京ICP备17029143号-3',
}
export default {
  data() {
    return {
      text: ''
    }
  },
  mounted() {
    Object.keys(Dict).forEach((hostname) => {
      if (new RegExp('^' + hostname, 'i').test(location.hostname)) {
        this.text = Dict[hostname]
      }
    })

    // this.initSkip()
  },
  methods: {
    initSkip() {
      setTimeout(() => {
        location.href = 'https://www.zhuanzhuan.com'
      }, 10 * 1000);
    }
  }
}
</script>
<style>
@import '@zz/sasscore/reset.scss';
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  * {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
  }

  width: 100vw;
  height: 100vh;

  .banner {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    object-fit: cover;
  }

  .footer-text {
    font-family: PingFangSC-Regular;
    font-size: 22px;
    color: #999999;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    font-weight: 400;

    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
