import { legoInit } from '@zz-common/lego'
import App from './App.vue'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@zz/sasscore/flexible.js'
import Vue from 'vue'
Vue.config.productionTip = false

class __App__ {
  init() {
    tryCatch(() => {
      // 全局初始化乐高埋点的值，默认合入backup
      legoInit()
    })
    // 注册vue实例
    this.mount()
  }

  mount() {
    new Vue({
      render: (h) => h(App)
    }).$mount('#app')
  }
}
const app = new __App__()
app.init()

function tryCatch(fn) {
  try {
    fn()
  } catch (err) {
    console.error(err)
  }
}
